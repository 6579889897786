import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Router } from "react-router";
import { withRouter } from "react-router-dom";
import { history } from "../../config/stores";
import { Header, Footer, MobileHeader } from "../../components";
import { Home, AboutUs, WhyUs, FreeMarket, Contacts } from "../../screens";

class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="dashboard-wrap">
          {window.innerWidth < 900 ? <MobileHeader /> : <Header />}
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/offer" component={Home} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/why-us" component={WhyUs} />
            <Route exact path="/free-market" component={FreeMarket} />
            <Route exact path="/contacts" component={Contacts} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading };
};

export default withRouter(connect(mapStateToProps)(Routes));
