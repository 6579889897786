import React, { useState, useEffect } from "react";
import { Base64 } from "js-base64";
import { TitleComponent, InputComponent, SwitchButton } from "../../components";
import Files from "../../utilities/Files";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useAnalyticsEventTracker from "../../utilities/useAnalyticsEventTracker";
import ReactGA from 'react-ga';

const OfferComponent = ({ scroll }) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.general.language);
  const gaEventTracker = useAnalyticsEventTracker('Offer form');
  
  const [contract, setContract] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [personalDataOffers, setPersonalDataOffers] = useState(false);
  const [company, setCompany] = useState("");
  const [EIK, setEIK] = useState("");
  const [contact, setContact] = useState("");
  const [phone, setPhone] = useState("");
  const [averageUse, setAverageUse] = useState("");
  const [file, setFile] = useState({ name: "", location: "" });
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    `${t("home.waitMessage")}`
  );
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Моля изчакайте няколко секунди.....") {
          newLoadingMessage = "Моля изчакайте няколко секунди.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);
  
  return (
    <div className="offer-component-container" ref={scroll}>
      <div className="form-container">
        <div className="text-container">
          <TitleComponent title={t("home.homeSectionOfferTitle")} />
          <p>{t("home.homeSectionOfferSubtitle")}</p>
          <div className="flex-container">
            <div className="input-element">
              <p>{t("home.company")}</p>
              <InputComponent
                value={company}
                onChange={(event) => setCompany(event.target.value)}
              />
            </div>
            <div className="input-element">
              <p>{t("home.uic")}</p>
              <InputComponent
                value={EIK}
                onChange={(event) => setEIK(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="input-element">
              <p>{t(("home.contactPerson"))}</p>
              <InputComponent
                value={contact}
                onChange={(event) => setContact(event.target.value)}
              />
            </div>
            <div className="input-element">
              <p>{t("home.phoneEmail")}</p>
              <InputComponent
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
          </div>
          <div className="flex-container">
            <div className="input-element">
              <p>{t("home.monthlyConsumation")}</p>
              <InputComponent
                placeholder={t("home.unit")}
                value={averageUse}
                onChange={(event) => setAverageUse(event.target.value)}
              />
            </div>
            <div className="input-element">
              <p>{t("home.invoiceApplication")}</p>
              <InputComponent
                value={file.name}
                fileUpload
                onUpload={(event) => {
                  const name = event.target.files[0].name;
                  Files.uploadFile(event.target.files[0]).then((result) => {
                    setFile({ name: name, location: result.location });
                  });
                }}
              />
              <p id="grey">{t("home.energyPastPeriod")}</p>
            </div>
          </div>
          <div className="flex-container mobile switch-button">
            <p className="light">{t("home.contractText")}</p>
            <SwitchButton
              on={contract}
              onClick={() => setContract((prev) => !prev)}
            />
          </div>
          <p>{t("home.commentsText")}</p>
          <div className="flex-container">
            <InputComponent
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              placeholder={t("home.writeComment")}
            />
          </div>
          <div className="checkbox-wrapper">
            <div
              className="checkbox-container"
              onClick={() => setPersonalData((prev) => !prev)}
            >
              {personalData && <div className="checkmark" />}
            </div>
            <p className="light">{t("home.dataAgreement")}</p>
          </div>
          <div className="checkbox-wrapper">
            <div
              className="checkbox-container"
              onClick={() => setPersonalDataOffers((prev) => !prev)}
            >
              {personalDataOffers && <div className="checkmark" />}
            </div>
            <p className="light">{t("home.personalDataAgreement")} </p>
          </div>
          <div className="flex-container flex-end mobile">
            {/* <div onClick={() => {
              console.log('test')
                  ReactGA.event({
                    category: 'Button Click Test',
                    action: 'Clicked on Send',
                    label: 'Offer form',
                  });
            }}>Test button</div> */}
            <div
              className="send-button"
              onClick={() => {
                if (!phone || !EIK || !contact || !company) {
                  setError(true);
                } else {
                  setError(false);
                  setLoading(true);
                  setSuccess(false);
                  // gaEventTracker('send')

                  ReactGA.event({
                    category: 'Button Click',
                    action: 'Clicked on Send',
                    label: 'Offer form',
                  });

                  if (language === 'bg') {
                    window
                    .fetch("https://mailer.fidweb.net/mail/send/energyOp", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Basic " +
                          Base64.encode("energyOp:dsfsd23423+,!231!!asd1Fdss"),
                      },
                      body: JSON.stringify({
                        from: "team@fidweb.net",
                        to:
                          "team@fidweb.net,office@eastraholding.com,trade@energy-op.eu",
                        subject: "subject",
                        body: ` Изпратено от: ${contact} , \n Компания: ${company}, \n Телефон/И-мейл: ${phone}, \n ЕИК: ${EIK}, \n Консумация: ${averageUse},\n Фактура ${
                          file.location || ""
                        },\n Коментар: ${comment},\n  Договор с доставчик на ел. енергия: ${
                          contract ? "Да" : "Не"
                        }`,
                        notifySubject: `EnergyOP`,
                      }),
                    })
                    .then((result) => {
                      setLoading(false);
                      setSuccess(true);
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                  }

                  else if (language === 'en') {
                    window
                    .fetch("https://mailer.fidweb.net/mail/send/energyOp", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization:
                          "Basic " +
                          Base64.encode("energyOp:dsfsd23423+,!231!!asd1Fdss"),
                      },
                      body: JSON.stringify({
                        from: "team@fidweb.net",
                        to:
                          "team@fidweb.net,office@eastraholding.com,trade@energy-op.eu",
                        subject: "subject",
                        body: ` Sent by: ${contact} , \n Company: ${company}, \n Mobile phone/ Email: ${phone}, \n BULSTAT Unified Identification Code: ${EIK}, \n Consumption: ${averageUse},\n Invoice ${
                          file.location || ""
                        },\n Request: ${comment},\n  Contract with a provider of electric energy: ${
                          contract ? "Yes" : "No"
                        }`,
                        notifySubject: `EnergyOP`,
                      }),
                    })
                    .then((result) => {
                      setLoading(false);
                      setSuccess(true);
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                  }
                }
              }}
            >
              <p>{t("general.send")}</p>
              <div className="arrow-right-blue" />
            </div>
          </div>
          {loading && <p>{loadingMessage}</p>}
          {success && <p>{t("home.sentRequest")}</p>}
          {error && <p>{t("home.inputError")}</p>}
        </div>
      </div>
      <div className="form-image" />
    </div>
  );
};

export default OfferComponent;
