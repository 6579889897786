import React from "react";
import { TitleComponent } from "../../components";
import { history } from "../../config/stores";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const MainTextComponent = ({
  title,
  subtitle,
  text,
  image,
  buttons,
  borderText,
  right,
  onOfferClick,
  large,
  small,
  mediumLarge,
  mediumLarger,
  mediumSmall,
  onQuestionsClick
}) => {
  const { t } = useTranslation();

  return (
    <div
      key={`${title ? title : subtitle ? subtitle : '0-'}1-main-text-component`}
      className={`main-text-component ${large && "large"} ${small && "small"} ${
        mediumLarger && "medium-larger"
      } ${mediumSmall && "medium-small"} ${mediumLarge && "medium-large"}`}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={`overlay-section ${right && "right"}`}>
        <div className="text-container">
          <TitleComponent title={title && title} />
          {subtitle && <p id="subtitle">{subtitle}</p>}

          {borderText && (
            <div className="border-text-container">
              {borderText.map((paragraph, index) => {
                return <p key={`${paragraph.slice(0,8)}-${index}`}>{paragraph}</p>;
              })}
            </div>
          )}
          {text &&
            text.map((paragraph, index) => {
              return <p key={`${paragraph.slice(0,5)}-${index * 10}`}>{paragraph}</p>;
            })}
          {buttons && (
            <div className="buttons-container">
              {buttons.map((button, index) => {
                return (
                  <div
                    key={`${button}-${index * 150}`}
                    className="button-container"
                    onClick={() => {
                      if (button.toUpperCase() === "ПРОЧЕТЕТЕ ПОВЕЧЕ" || button === t("general.readMore")) {
                        if (title.toUpperCase() === "СВОБОДЕН ПАЗАР" || title === t("home.homeSectionTwoTitle")) {
                          history.push("/free-market");
                          window.scrollTo({ behavior: "smooth", top: 65 });
                        } else {
                          history.push("/why-us");
                          window.scrollTo({
                            behavior: "smooth",
                            top: 65,
                          });
                        }
                      } else if (button.toUpperCase() === "ПОИСКАЙТЕ ОФЕРТА" || button === t("general.getOffer")) {
                        onOfferClick();
                      } else if (button.toUpperCase() === "ИМАТЕ ОЩЕ ВЪПРОСИ" || button === t("general.moreQuestions")) {
                        onQuestionsClick();
                      }
                    }}
                  >
                    <p className="uppercase">{button}</p>
                    <div className="arrow-right-blue" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainTextComponent;
