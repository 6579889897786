import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { setLanguage } from "../../actions";

const MobileHeader = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const location = useLocation().pathname;
  const language = useSelector((state) => state.general.language);

  useEffect(() => {
    if (open) {
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflowY = "scroll";
    }
  }, [open]);

  return (
    <div className="mobile-header-container">
      {!open ? (
        <div className="unopened-header-container">
          <div className="menu-icon" onClick={() => setOpen(true)} />
          <div className="logo" onClick={() => history.push("/")} />
          <div className="flex-dummy" />
        </div>
      ) : (
        <div className="opened-header-container">
          <div className="header-overlay">
            <div className="close-btn" onClick={() => setOpen(false)} />
            <div className="flex-column">
              <div
                className={`menu-element ${location === "/" && "selected"}`}
                onClick={() => {
                  setOpen(false);
                  history.push("/");
                }}
              >
                {t("general.headerHome")}
              </div>
              <div
                className={`menu-element ${
                  location === "/about-us" && "selected"
                }`}
                onClick={() => {
                  setOpen(false);
                  history.push("/about-us");
                }}
              >
                {t("general.headerAbout")}
              </div>
              <div
                className={`menu-element ${
                  location === "/why-us" && "selected"
                }`}
                onClick={() => {
                  setOpen(false);
                  history.push("/why-us");
                }}
              >
                {t("general.headerWhyChooseUs")}
              </div>
              <div
                className={`menu-element ${
                  location === "/free-market" && "selected"
                }`}
                onClick={() => {
                  setOpen(false);
                  history.push("/free-market");
                }}
              >
                {t("general.headerMarket")}
              </div>
              <div
                className={`menu-element ${
                  location === "/contacts" && "selected"
                }`}
                onClick={() => {
                  setOpen(false);
                  history.push("/contacts");
                }}
              >
                {t("general.headerContact")}
              </div>

              <div className="menu-element languages-container">
                <div
                  className={`lang ${language === "bg" ? "active" : ""}`}
                  onClick={() => {
                    dispatch(setLanguage("bg"));
                    i18n.changeLanguage("bg");
                  }}
                >
                  БГ
                </div>
                <div className="line">|</div>
                <div
                  className={`lang ${language === "en" ? "active" : ""}`}
                  onClick={() => {
                    dispatch(setLanguage("en"));
                    i18n.changeLanguage("en");
                  }}
                >
                  EN
                </div>
              </div>

            </div>
            <div
              className="offer-button"
              onClick={() => {
                setOpen(false);
                history.push("/offer");
              }}
            >
              {t("general.offer")}
            </div>
          </div>
          <div className="header-dark-overlay" />
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
