import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MainTextComponent, OfferComponent } from "../../components";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Home = () => {
  const scrollRef = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.pathname === "/offer") {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="home">
      <MainTextComponent
        small
        right
        title={t("home.homeSectionOneTitle")}
        borderText={[
          `${t("home.homeSectionOneParagraphOne")}`,
          `${t("home.homeSectionOneParagraphTwo")}`,
          `${t("home.homeSectionOneParagraphThree")}`,
          `${t("home.homeSectionOneParagraphFour")}`,
        ]}
        image={require("../../assets/images/solar-872804_1920-min.jpg")}
      />
      <MainTextComponent
        mediumSmall
        title={t("home.homeSectionTwoTitle")}
        subtitle={t("home.homeSectionTwoSubtitle")}
        text={[
          `${t("home.homeSectionTwoParagraphOne")}`,
          `${t("home.homeSectionTwoParagraphTwo")}`,
        ]}
        image={require("../../assets/images/svoboden-nachalo.jpg")}
        buttons={[`${t("general.readMore")}`, `${t("general.getOffer")}`]}
        onOfferClick={() => scrollRef.current.scrollIntoView({ behavior: "smooth" })}
      />
      <MainTextComponent
        mediumSmall
        right
        title={t("home.homeSectionThreeTitle")}
        borderText={[
          `${t("home.homeSectionThreeParagraphOne")}`,
          `${t("home.homeSectionThreeParagraphTwo")}`,
          `${t("home.homeSectionThreeParagraphThree")}`
        ]}
        image={require("../../assets/images/nachalo-predimstwa.jpg")}
        buttons={[`${t("general.readMore")}`, `${t("general.getOffer")}`]}
        onOfferClick={() =>
          scrollRef.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <OfferComponent scroll={scrollRef} />
    </div>
  );
};

export default Home;
