import React, { useRef } from "react";
import "./styles.scss";

const InputComponent = ({
  disabled,
  value,
  placeholder,
  onChange,
  fileUpload,
  onUpload,
}) => {
  const fileRef = useRef(null);
  return (
    <div className="input-component">
      <input
        className="form-input"
        type="text"
        value={value}
        placeholder={placeholder || ""}
        onChange={onChange}
        disabled={disabled || fileUpload}
      />
      {fileUpload && (
        <input
          type="file"
          ref={fileRef}
          onChange={(event) => {
            onUpload(event);
          }}
          style={{ display: "none" }}
        />
      )}
      {fileUpload && (
        <div
          className="file-upload-button"
          onClick={() => fileRef.current.click()}
        />
      )}
    </div>
  );
};

export default InputComponent;
