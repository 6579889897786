import React from "react";
import { ContactsComponent } from "../../components";
import "./styles.scss";

const Contacts = () => {
  return (
    <div className="contacts-container">
      <ContactsComponent />
    </div>
  );
};

export default Contacts;
