import React, { useRef } from "react";
import { MainTextComponent, OfferComponent } from "../../components";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const WhyUs = () => {
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div className="why-us">
      <MainTextComponent
        small
        right
        title={t("whyUs.whyUsSectionOneTitle")}
        text={[
          `${t("whyUs.whyUsSectionOneParagraphOne")}`,
        ]}
        image={require("../../assets/images/zashto-da-ni-izberete.jpg")}
      />
      <MainTextComponent
        large
        title={t("whyUs.whyUsSectionTwoTitle")}
        borderText={[
          `${t("whyUs.whyUsSectionTwoParagraphOne")}`,
          `${t("whyUs.whyUsSectionTwoParagraphTwo")}`,
          `${t("whyUs.whyUsSectionTwoParagraphThree")}`,
          `${t("whyUs.whyUsSectionTwoParagraphFour")}`,
          `${t("whyUs.whyUsSectionTwoParagraphFive")}`,
          `${t("whyUs.whyUsSectionTwoParagraphSix")}`,
        ]}
        buttons={[`${t("home.homeSectionOfferTitle")}`]}
        onOfferClick={() => scrollRef.current.scrollIntoView({ behavior: "smooth" })}
        image={require("../../assets/images/grass-1842296_1920-min.jpg")}
      />
      <MainTextComponent
        right
        mediumSmall
        title={t("whyUs.whyUsSectionThreeTitle")}
        borderText={[
          `${t("whyUs.whyUsSectionThreeParagraphOne")}`,
          `${t("whyUs.whyUsSectionThreeParagraphTwo")}`,
          `${t("whyUs.whyUsSectionThreeParagraphThree")}`,
        ]}
        buttons={[`${t("home.homeSectionOfferTitle")}`]}
        onOfferClick={() => scrollRef.current.scrollIntoView({ behavior: "smooth" })}
        image={require("../../assets/images/nachalo-klient.jpg")}
      />
      <OfferComponent scroll={scrollRef} />
    </div>
  );
};

export default WhyUs;
