import { GeneralTypes } from '../actions'


let lang = localStorage.getItem("code");

const initialState = {
  language: lang || 'bg'
}

const setLanguage = (payload) => {
    localStorage.setItem('code', payload)
    return payload
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case GeneralTypes.SET_LANGUAGE:
      return { ...state, language: setLanguage(action.payload) }
    default:
      return state
  }
}

export default general
