import React, { useRef } from "react";
import { MainTextComponent, QuestionsComponent } from "../../components";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const FreeMarket = () => {
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  return (
    <div className="about-us">
      <MainTextComponent
        right
        mediumLarger
        title={t("aboutMarket.aboutSectionOneTitle")}
        subtitle={t("aboutMarket.aboutSectionOneQuestionOne")}
        text={[
          `${t("aboutMarket.aboutSectionOneAnswerOne")}`
        ]}
        image={require("../../assets/images/regulirane.jpg")}
      />
      <MainTextComponent
        mediumSmall
        title={t("aboutMarket.aboutSectionTwoTitle")}
        subtitle={t("aboutMarket.aboutSectionTwoQuestionOne")}
        text={[
          `${t("aboutMarket.aboutSectionTwoAnswerOne")}`
        ]}
        image={require("../../assets/images/za-svobodniq.jpg")}
        buttons={[`${t("general.moreQuestions")}`]}
        onQuestionsClick={() =>
          scrollRef.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <QuestionsComponent scroll={scrollRef} />
    </div>
  );
};

export default FreeMarket;
