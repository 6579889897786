import S3FileUpload from "react-s3";
import { nanoid } from "nanoid";
import { s3Credentials } from "../config/settings";

window.Buffer = window.Buffer || require("buffer").Buffer;

const Files = {
  uploadFile(file, name) {
    if (file) {
      const uploadFile = new File([file], name || nanoid(), {
        type: file.type,
        lastModified: file.lastModified,
      });
      return S3FileUpload.uploadFile(uploadFile, s3Credentials);
    } else {
      return new Promise((resolve) => resolve(""));
    }
  },
};

export default Files;
