import React from "react";
import { GoogleMaps, TitleComponent } from "../../components";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const ContactsComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="contacts-component-container">
      <div className="left-side">
        <GoogleMaps
          address={"This Way"}
          location={{ lat: 42.66351854157128, lng: 23.30241151195724 }}
        />
      </div>
      <div className="right-side">
        <div className="text-container">
          <TitleComponent title={t("contacts.contactUs")} />
          <p id="margin">{t("contacts.contactUsSubtitle")}</p>
          <div className="flex-container">
            <p id="coloured">A:</p>
            <p>{t("general.address")}</p>
          </div>
          <div className="flex-container">
            <p id="coloured">E:</p>
            <p>trade@energy-op.eu </p>
          </div>
          <div className="flex-container">
            <p id="coloured">T:</p>
            <p>+02 963 13 30/+02 963 48 27</p>
          </div>
          <div className="flex-container">
            <p id="coloured">M:</p>
            <p>+359 878 43 09 09/+359 899 55 06 60</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsComponent;
