import React from "react";
import { history } from "../../config/stores";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div
        className="footer-logo"
        onClick={() => {
          history.push("/");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <div className="data-container">
        <h4>{t("contacts.contactUs")}</h4>
        <p>E: trade@energy-op.eu</p>
        <p>T: +02 963 13 30/+02 963 48 27</p>
        <p>М: +359 878 43 09 09/+359 899 55 06 60</p>
      </div>
      <div className="data-container">
        <h4>{t("general.location")}</h4>
        <p>{t("general.address")}</p>
      </div>
      <div className="data-container">
        <h4>{t("general.legalData")}</h4>
        <p
          id="cursor"
          onClick={() => window.open("/docs/gdpr-privacy_policy.docx")}
        >
          {t("general.privacyPolicy")}
        </p>
        <p id="cursor">{t("general.cookiePolicy")}</p>
      </div>
    </div>
  );
};

export default Footer;
