import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const SwitchButton = ({ on, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={`switch-button-container ${on && "on"}`} onClick={onClick}>
      <span>{t("general.yes")}</span>
      <div className={`switch-circle ${on && "on"}`} />
      <span>{t("general.no")}</span>
    </div>
  );
};

export default SwitchButton;
