import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";
import translationBG from "./translations/bg.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEN },
    bg: { translation: translationBG },
  },
  lng: localStorage.getItem('code'),
  fallbackLng: "bg",
});

window.i18n = i18n;

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'))
registerServiceWorker()
