import React from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { setLanguage } from "../../actions";

const Header = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const location = useLocation().pathname;
  const language = useSelector((state) => state.general.language);

  return (
    <div className="header-container">
      <div className="logo" onClick={() => history.push("/")} />
      <div className="header-items">
        <div
          className={`header-item ${location === "/about-us" && "selected"}`}
          onClick={() => history.push("/about-us")}
        >
          {t("general.headerAbout")}
        </div>
        <div className="border" />
        <div
          className={`header-item ${location === "/why-us" && "selected"}`}
          onClick={() => history.push("/why-us")}
        >
          {t("general.headerWhyChooseUs")}
        </div>
        <div className="border" />
        <div
          className={`header-item ${location === "/free-market" && "selected"}`}
          onClick={() => history.push("/free-market")}
        >
          {t("general.headerMarket")}
        </div>
        <div className="border" />
        <div
          className={`header-item ${location === "/contacts" && "selected"}`}
          onClick={() => history.push("/contacts")}
        >
          {t("general.headerContact")}
        </div>
      </div>
      <div className="header-button" onClick={() => history.push("/offer")}>
        {t("general.offer")}
      </div>

      <div className="languages-container">
            <div
              className={`lang ${language === "bg" ? "active" : ""}`}
              onClick={() => {
                dispatch(setLanguage("bg"));
                i18n.changeLanguage("bg");
              }}
            >
              БГ
            </div>
            <div className="line">|</div>
            <div
              className={`lang ${language === "en" ? "active" : ""}`}
              onClick={() => {
                dispatch(setLanguage("en"));
                i18n.changeLanguage("en");
              }}
            >
              EN
            </div>
      </div>
    </div>
  );
};

export default Header;
