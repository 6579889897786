import { mapTo, delay } from 'rxjs/operators'
import { combineEpics, ofType } from 'redux-observable'
import { StarterTypes, stopLoading } from '../actions'

export const loadingEpic = (action$, state$) => {
  return action$.pipe(
    ofType(StarterTypes.START_LOADING),
    delay(1000),
    mapTo(stopLoading())
  )
}

export const rootEpic = combineEpics(loadingEpic)
