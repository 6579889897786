import React, { useState, useEffect } from "react";
import { history } from "../../config/stores";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const QuestionsComponent = ({ scroll }) => {
  const { t } = useTranslation();
  const [selectedQA, setSelectedQA] = useState(0);
  const [mobileSelectedQA, setMobileSelectedQA] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  useEffect(() => {
    if (window.innerWidth < 900) {
      setSelectedQA(null);
    }
  }, []);

  const questionsData = [
    {
      question:
      `${t("aboutMarket.aboutSectionQuestionOne")}`,
      answers: [
        `${t("aboutMarket.aboutSectionQuestionOneAnswerOne")}`,
        `${t("aboutMarket.aboutSectionQuestionOneAnswerTwo")}`,
        `${t("aboutMarket.aboutSectionQuestionOneAnswerThree")}`,
      ],
    },
    {
      question:
        `${t("aboutMarket.aboutSectionQuestionTwo")}`,
      answers: [
        `${t("aboutMarket.aboutSectionQuestionTwoAnswerOne")}`,
        `${t("aboutMarket.aboutSectionQuestionTwoAnswerTwo")}`,
      ],
    },
    {
      question:
      `${t("aboutMarket.aboutSectionQuestionThree")}`,
      answers: [
        `${t("aboutMarket.aboutSectionQuestionThreeAnswerOne")}`
      ],
    },
    {
      question: `${t("aboutMarket.aboutSectionQuestionFour")}`,
      answers: [
        `${t("aboutMarket.aboutSectionQuestionFourAnswerOne")}`,
        `${t("aboutMarket.aboutSectionQuestionFourAnswerTwo")}`,
        `${t("aboutMarket.aboutSectionQuestionFourAnswerThree")}`,
        `${t("aboutMarket.aboutSectionQuestionFourAnswerFour")}`,
        `${t("aboutMarket.aboutSectionQuestionFourAnswerFive")}`
      ],
    },
    {
      question: `${t("aboutMarket.aboutSectionQuestionFive")}`,
      answers: [
        `${t("aboutMarket.aboutSectionQuestionFiveAnswerOne")}`,
        {
          bullets: [
            `${t("aboutMarket.aboutSectionQuestionFiveAnswerOneBulletOne")}`,
            `${t("aboutMarket.aboutSectionQuestionFiveAnswerOneBulletTwo")}`,
            `${t("aboutMarket.aboutSectionQuestionFiveAnswerOneBulletThree")}`,
          ],
        },
        `${t("aboutMarket.aboutSectionQuestionFiveAnswerTwo")}`,
      ],
    },
  ];
  return (
    <div className="questions-container" ref={scroll}>
      <div className="questions-header-element">
        {t("aboutMarket.aboutSectionQuestionsTitle")}
      </div>
      {window.innerWidth < 900 ? (
        <>
          {questionsData.map((question, index) => {
            return (
              <div className="question-mobile-container" key={`${question}-mobile-${index * 150}`}>
                <div
                  className="flex-container"
                  onClick={() => {
                    let newState = mobileSelectedQA.slice(0);
                    newState[index] = !newState[index];
                    setMobileSelectedQA(newState);
                  }}
                >
                  <p>{question.question}</p>
                  <div className="arrow-down" />
                </div>
                {mobileSelectedQA[index] && (
                  <div className="answers-mobile-container">
                    {question.answers.map((answer) => {
                      if (answer.bullets) {
                        return answer.bullets.map((bullet) => {
                          return <li>{bullet}</li>;
                        });
                      } else {
                        return <p>{answer}</p>;
                      }
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div className="flex-container">
          <div className="left-container">
            <div className="text-container">
              <h4>{t("general.question")}</h4>
              {questionsData.map((question, index) => {
                return (
                  <div
                    className="flex-container"
                    onClick={() => setSelectedQA(index)}
                    key={`${question}-desktop-${index * 140}`}
                  >
                    <p id={index === selectedQA ? "selected" : "not-selected"}>
                      {question.question}
                    </p>
                    {index === 0 && <div className="blue-arrow" />}
                  </div>
                );
              })}
              <div
                className="flex-container flex-start"
                onClick={() => {
                  history.push("/contacts");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <p id="blue">{t("general.moreQuestionsCase")}</p>
                <div className="blue-arrow" />
              </div>
            </div>
          </div>
          <div className="right-container">
            <h4>{t("general.answer")}</h4>
            <div className="scroll-container">
              {questionsData[selectedQA].answers.map((answer, answerIndex) => {
                if (answer.bullets) {
                  return answer.bullets.map((bullet, i) => {
                    return <li key={`${bullet}-desktop-${i}`}>{bullet}</li>;
                  });
                } else {
                  return <p key={`${answer}-desktop-${answerIndex}`}>{answer}</p>;
                }
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsComponent;
