import React from "react";
import "./styles.scss";

function TitleComponent({ title, small, mobileSpecial }) {
  return (
    <div
      className={`title-container ${mobileSpecial && "mobile-special"} ${
        small && "small"
      }`}
      key={`${title ? title : subtitle ? subtitle : '0-'}1-title-component`}
    >
      <h1>{title}</h1>
      <div className="green-line" />
    </div>
  );
}

export default TitleComponent;
