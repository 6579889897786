import React from "react";
import { MainTextComponent } from "../../components";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us">
      <MainTextComponent
        right
        mediumLarge
        title={t("about.aboutSectionOneTitle")}
        text={[
          `${t("about.aboutSectionOneParagraphOne")}`,
          `${t("about.aboutSectionOneParagraphTwo")}`,
        ]}
        image={require('../../assets/images/solar-system-3599530_1920-min.jpg')}
      />
      <MainTextComponent
        mediumLarge
        title={t("about.aboutSectionTwoTitle")}
        text={[
          `${t("about.aboutSectionTwoParagraphOne")}`,
          `${t("about.aboutSectionTwoParagraphTwo")}`,
        ]}
        image={require("../../assets/images/za-nas.jpg")}
      />
      <MainTextComponent
        right
        mediumLarge
        title={t("about.aboutSectionThreeTitle")}
        text={[
          `${t("about.aboutSectionThreeParagraphOne")}`
        ]}
        image={require("../../assets/images/za-nas-foto.jpg")}
      />
    </div>
  );
};

export default AboutUs;
